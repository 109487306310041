const caps = {
  foundational: [
    {points: 0, cap: 3},
    {points: 30, cap: 6},
    {points: 73, cap: 9},
    {points: 133, cap: 12},
    {points: 195, cap: 15},
  ],
  system:  [
    {points: 0, cap: 3},
    {points: 10, cap: 6},
    {points: 27, cap: 9},
    {points: 57, cap: 12},
    {points: 105, cap: 15},
  ],
};

export default caps;

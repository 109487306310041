import { useEffect, useState } from "react";
import points from "../../_assets/constants/points";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";

const LevelProgression = (props) => {
  const { details, handleChange } = props;
  const [ activeRating, setActiveRating ] = useState(0);

  useEffect(() => {
    setActiveRating(details.ratings[details.activeCategory]);
  },[details])

  // Set a rating in state.
  const setRating = (e) => {
    const target = {
      action: 'rating',
      name: details.activeCategory,
      value: e.target.value,
    };
    handleChange(target);
  };

  // Build the mini-table.
  const ratingTable = () => {
    let output = [];
    for (let i = 1; i < 6; i++) {
      const highlight = (activeRating === i) ? 'highlight' : ''
      output.push(
        <td
          className={`rating-table-cell ${highlight}`}
          key={i}
        ><button value={i} onClick={setRating}>{i}</button></td>);
    }
    return output;
  }

  // Get the point value of this rating.
  const getValue = () => {
    let value = 0;
    const str = details.activeCategory.split('_');
    if (str[0] !== undefined) {
      value = points[str[0]][activeRating];
    }
    return value;
  };

  // Return HTML.
  return (
    <>
    <table className="rating-table">
      <thead>
        <tr>
          <th hidden={true}>Rating Table</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {ratingTable()}
        </tr>
      </tbody>
    </table>
    <p className="point-value">Worth {getValue()} points</p>
    </>
  );

};

LevelProgression.propTypes = propTypes();
LevelProgression.defaultProps = defaultProps();

export default LevelProgression;

import caps from "../constants/caps";
import levels from "../constants/levels";
import points from "../constants/points";

// Turn the score into a level.
const getGradedLevel = (score) => {
  let gradedLevel;
  levels.forEach((level) => {
    if (score >= level.points) {
      gradedLevel = level.level;
    }
  });
  return gradedLevel;
};

const getCurrentPoints = (gradedLevel) => {
  let thisLevel;
  levels.forEach((level) => {
    if (level.level === (gradedLevel)) {
      thisLevel = level.points;
    }
  });
  return thisLevel;
};

// Get the number of points to next level.
const getNextLevelPoints = (score, gradedLevel) => {
  let nextLevel;
  levels.forEach((level) => {
    if (level.level === (gradedLevel + 1)) {
      nextLevel = level.points;
    }
  });
  return nextLevel - score;
};

// Get level caps.
const getLevelCap = (rawScore, type) => {
  const levelCaps = caps[type];
  let cappedLevel = 3;
  levelCaps.forEach((level) => {
    if (rawScore >= level.points) {
      cappedLevel = level.cap;
    }
  });
  return cappedLevel;
};

// Get points to next milestone cap.
const getNextPoints = (rawScore, type) => {
  const next = getLevelCap(rawScore, type) + 3;
  const levelCaps = caps[type];
  let cappedLevel = 3;
  levelCaps.forEach((level) => {
    if (next === level.cap) {
      cappedLevel = level.points;
    }
  });
  return cappedLevel - rawScore;
};

// Takes the ratings and calculates raw score.
const calculateScore = (state) => {
  let score = 0;
  let foundationalScore = 0;
  let systemScore = 0;

  for (const [key, value] of Object.entries(state.ratings)) {
    const str = key.split('_');
    if (str[0] !== undefined) {
      score = score + points[str[0]][value];
      if (str[0] === 'foundational') {
        foundationalScore = foundationalScore + points[str[0]][value];
      }
      else {
        systemScore = systemScore + points[str[0]][value];
      }
    }
  }

  state.score = score;
  state.foundationalScore = foundationalScore;
  state.systemScore = systemScore;
  state.gradedLevel = getGradedLevel(score);
  state.thisLevelPoints = getCurrentPoints(state.gradedLevel);
  state.foundationalCap = getLevelCap(foundationalScore, 'foundational');
  state.systemCap = getLevelCap(systemScore, 'system');
  state.cappedLevel = Math.min(state.foundationalCap, state.systemCap);
  state.nextLevelPoints = getNextLevelPoints(score, state.gradedLevel);
  state.nextFoundationalPoints = getNextPoints(foundationalScore, 'foundational');
  state.nextSystemPoints = getNextPoints(systemScore, 'system');

  return state;
};

export default calculateScore;

const initialState = {
  version: '4.0.1',
  recordedVersion: '',
  name: '',
  evaluator: '',
  cohort: 'User Experience Design',
  level: 1,
  role: 'self',
  score: 0,
  activeType: 'foundational',
  activeCategory: 'foundational_one',
  gradedLevel: 1,
  cappedLevel: 3,
  foundationalScore: 0,
  systemScore: 0,
  foundationalCap: 3,
  systemCap: 3,
  thisLevelPoints: 0,
  nextLevelPoints: 10,
  nextFoundationalPoints: 30,
  nextSystemPoints: 10,
  ratings: {
    foundational_one: 0,
    foundational_two: 0,
    foundational_three: 0,
    foundational_four: 0,
    delivering_planning: 0,
    delivering_communication: 0,
    delivering_professionalism: 0,
    strengthening_initiative: 0,
    strengthening_learning: 0,
    strengthening_mentorship: 0,
    supporting_complexity: 0,
    supporting_value: 0,
    supporting_business: 0,
  },
};

export default initialState;

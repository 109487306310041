const levels = [
  {points: 0,   level:  1},
  {points: 10,  level:  2},
  {points: 20,  level:  3},
  {points: 40,  level:  4},
  {points: 60,  level:  5},
  {points: 80,  level:  6},
  {points: 100, level:  7},
  {points: 130, level:  8},
  {points: 160, level:  9},
  {points: 190, level: 10},
  {points: 230, level: 11},
  {points: 270, level: 12},
  {points: 300, level: 13},
  {points: 330, level: 14},
  {points: 360, level: 15},
  {points: 500, level: 16},
];

export default levels;

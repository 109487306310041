import { useEffect, useState } from "react";
import levels from "../../_assets/constants/levels";
import roles from "../../_assets/constants/roles";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";

// Sets the details of the evaluation.
const DetailsForm = (props) => {
  const { details, handleChange } = props;

  const [ cohorts, setCohorts ] = useState([]);
  const username = "palantir";
  const password = "happy 20th";

  // Create a Basic Authentication token by encoding the username and password
  const base64Credentials = btoa(`${username}:${password}`);

  useEffect(() => {
    fetch("https://www.palantir.net/jsonapi/node/rbs_cohorts", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Basic ${base64Credentials}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setCohorts(data.data);
      });
  }, []);

  // The form itself.
  return (
    <div className="header-form grid">
      <form>
        <div className="grid-rows">
          <div className="form-row-1">
            <label htmlFor="name" className="name-label">RBS Evaluation for:</label>
            <input
              id="name"
              type="text"
              className="name-input"
              placeholder="Name"
              value={details.name}
              name="name"
              autoComplete="off"
              onChange={e => handleChange(e.target)}
            />
          </div>
          <div className="form-row-2">
            <div className="form-input">
              <label htmlFor="cohort">Cohort:</label>
              <span className="select-wrapper">
                <select
                  id="cohort"
                  name="cohort"
                  value={details.cohort}
                  onChange={e => handleChange(e.target)}
                >
                  {
                    cohorts.length && (
                      cohorts.map(cohort => (
                        <option key={cohort.attributes.drupal_internal__nid} value={cohort.attributes.title}>
                          {cohort.attributes.title}
                        </option>
                      ))
                    )
                  }
                </select>
              </span>
            </div>
            <div className="form-input">
              <label htmlFor="level">Current Level:</label>
              <span className="select-wrapper">
                <select
                  id="level"
                  className="small"
                  name="level"
                  value={details.level}
                  onChange={e => handleChange(e.target)}
                >
                  {levels.map(level => (
                    <option key={level.level}>
                      {level.level}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className="form-input">
              <label htmlFor="evaluator">Who is using this tool?</label>
              <span className="input-wrapper">
                <input
                  id="evaluator"
                  type="text"
                  className="evaluator-input"
                  placeholder="Your Name"
                  value={details.evaluator}
                  name="evaluator"
                  onChange={e => handleChange(e.target)}
                />
              </span>
            </div>
            <div className="form-input">
              <label htmlFor="role">Your role(s):</label>
              <span className="select-wrapper">
                <select
                  id="role"
                  name="role"
                  value={details.role}
                  onChange={e => handleChange(e.target)}
                >
                  {roles.map(role => (
                    <option key={role.key} value={role.key}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

};

DetailsForm.propTypes = propTypes();
DetailsForm.defaultProps = defaultProps();

export default DetailsForm;

import { useEffect, useState } from "react";
import "../../_assets/css/level-descriptions.css";
import LevelProgression from "../LevelProgression/LevelProgression.es6";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";
import { Markup } from 'interweave';

const LevelDescriptions = (props) => {
	const { details, handleChange, elements } = props;

	const [ activeCategory, setActiveCategory ] = useState({
		label: "",
		description: "",
		level_1: "",
		level_2: "",
		level_3: "",
		level_4: "",
		level_5: "",
	});
	
	// Set the active category display.
	useEffect(() => {
		if (details.activeCategory.includes('foundational')) {
			for (let skill in elements[details.activeType]["Foundational"]) {
				if (
					elements[details.activeType]["Foundational"][skill].attributes.field_keys === details.activeCategory
				) {
					setActiveCategory({
						label: elements[details.activeType]["Foundational"][skill].attributes.field_label,
						description: elements[details.activeType]["Foundational"][skill].attributes.field_description,
						level_1: elements[details.activeType]["Foundational"][skill].attributes.field_level_1_behaviors,
						level_2: elements[details.activeType]["Foundational"][skill].attributes.field_level_2_behaviors,
						level_3: elements[details.activeType]["Foundational"][skill].attributes.field_level_3_behaviors,
						level_4: elements[details.activeType]["Foundational"][skill].attributes.field_level_4_behaviors,
						level_5: elements[details.activeType]["Foundational"][skill].attributes.field_level_5_behaviors
					});
				}
			}
		} else {
			const parent = details.activeCategory.split('_');
			const categoryKey = parent[0].charAt(0).toUpperCase() + parent[0].slice(1);
			for (let skill in elements.system[categoryKey]) {
				if (elements.system[categoryKey][skill].attributes.field_keys === details.activeCategory) {
					setActiveCategory({
						label: elements.system[categoryKey][skill].attributes.field_label,
						description: elements.system[categoryKey][skill].attributes.field_description,
						level_1: elements.system[categoryKey][skill].attributes.field_level_1_behaviors,
						level_2: elements.system[categoryKey][skill].attributes.field_level_2_behaviors,
						level_3: elements.system[categoryKey][skill].attributes.field_level_3_behaviors,
						level_4: elements.system[categoryKey][skill].attributes.field_level_4_behaviors,
						level_5: elements.system[categoryKey][skill].attributes.field_level_5_behaviors
					});
				}
			}
		}
	}, [details, elements]);

	// Set current data.
	const activeLevel = details.ratings[details.activeCategory];
	const levelKey = `level_${activeLevel}`;
	const nextLevelKey = `level_${activeLevel + 1}`;

	if (activeCategory.label === '') {
		return '';
	}

	// Return HTML.
	return (
		<div className="track">
			<header className="track-header">
				<h2 className="track__title">
					<span>{activeCategory.label}</span>
				</h2>
				<div className="track__description">
					<span>{activeCategory.description}</span>
				</div>
			</header>
			<div>
				<LevelProgression details={details} handleChange={handleChange} activeCategory={activeCategory}/>
				<div className="track__content">
					{activeCategory[levelKey] &&
						<div className="current-level">
							<h3>Example behaviors:</h3>
							<ul>
								{activeCategory[levelKey].value.split("\r\n").map((behavior, i) => (
									<li key={i}><Markup content={behavior} /></li>
								))}
							</ul>
						</div>
					}
					{activeCategory[nextLevelKey] &&
						<div className="next-level">
							<h3>Next-level behaviors:</h3>
							<ul>
								{activeCategory[nextLevelKey].value.split("\r\n").map((behavior, i) => (
									<li key={i}><Markup content={behavior} /></li>
								))}
							</ul>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

LevelDescriptions.propTypes = propTypes();
LevelDescriptions.defaultProps = defaultProps();

export default LevelDescriptions;

import React from 'react';
import jsPDF from 'jspdf'; // Import the jspdf library
import html2canvas from 'html2canvas'; // Import the html2canvas library
import moment from 'moment';
import '../../../_assets/css/export-button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

const ExportToPDFButton = () => {
  const handleExportPDF = () => {
    // Create a new jsPDF instance with 'p' for portrait orientation, 'mm' for millimeters, and 'a4' for A4 page size
    const pdf = new jsPDF('p', 'mm', 'a4');

    // Use html2canvas to capture the entire content of the current webpage
    html2canvas(document.body).then((canvas) => {
      const imgData = canvas.toDataURL('image/png'); // Convert canvas to PNG image data
      const imgWidth = 210; // Width of the PDF page in millimeters
      const pageHeight = 297; // Height of the PDF page in millimeters
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate image height to maintain aspect ratio
      let heightLeft = imgHeight;
      let position = 0;

      // Add the captured image to the PDF page and adjust for multiple pages if needed
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined,'FAST');
        heightLeft -= pageHeight;
      }

      const dateTime = moment().format('MM_DD_YYYY-hh_mmA');
      pdf.save(`rbs-score-${dateTime}.pdf`); // Save the generated PDF with the filename 'rbs-score-DATE-TIME.pdf'
    });
  };

  return (
    <>
      <button className="export-button" onClick={handleExportPDF}>
        <FontAwesomeIcon icon={faFileArrowDown} style={{color: "#ffffff", marginRight: "6px"}} />
        Export to PDF
      </button>
    </>
  );
};

export default ExportToPDFButton;

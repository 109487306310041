import { useState } from "react";
import useSWR from "swr";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";
import '../../_assets/css/app.css';
import NavBar from "../Elements/NavBar/NavBar.es6";
import Header from "../Elements/Header/Header.es6";
import LeftSidebar from "../Elements/LeftSidebar/LeftSidebar.es6";
import Content from "../Elements/Content/Content.es6";
import MilestoneTable from "../Elements/MilestoneTable/MilestoneTable.es6";
import Footer from "../Elements/Footer/Footer.es6";

const PageLayout = (props) => {
	const { details, handleChange } = props;

	const [ elements, setElements ] = useState({
		"foundational": {},
		"system": {},
	});
	const [ foundationalHeader, setFoundationHeader ] = useState({
		"label": "Foundational skills",
		"description": "",
	});
	const [ systemHeader, setSystemHeader ] = useState({
		"label": "System and Consulting skills",
		"description": "",
	});

	let endpoint = `https://www.palantir.net/jsonapi/node/rbs_cohorts?filter[title]=${details.cohort}` +
		'&include=field_foundational_skills,field_system_skills,field_system_skills.paragraphs.field_category';

	// Sorts skills according to order in Drupal node
	const sortById = (skills) => {
		let systemSkillsByCategory = {};

		// "Foundational" | "Delivering", "Strengthening", "Supporting"
		for (let skillCategory of Object.keys(skills)) {
			let sortedEntries = Object.entries(skills[skillCategory]).sort((a,b)=> {
				return a[1].attributes.drupal_internal__id - b[1].attributes.drupal_internal__id;
			})

			if (skillCategory === "Foundational") {
				return {"Foundational": Object.fromEntries(sortedEntries)};
			} else {
				// Loops through individual skills of "Delivering", etc.
				for (const skills of sortedEntries) {
					const skillName = skills[0];
					const skillNode = skills[1];

					systemSkillsByCategory = {
						...systemSkillsByCategory,
						[skillCategory]: {
							...systemSkillsByCategory[skillCategory],
							[skillName]: skillNode
						}
					}
				}
			}
		}

		// Sorts skill category sections (ie. "Delivering") alphabetically
		const sortedAlpha = Object.entries(systemSkillsByCategory).sort((a,b)=> {
			return a[0].localeCompare(b[0]);
		})
		
		return Object.fromEntries(sortedAlpha);
	}

	// Sets skill name/skill node as key/value pairs, later to be inserted into state object (elements)
	const setElementsStructure = (nodes, skillType) => {
		let foundationalSkills = {};
		let systemSkills = {};

		for (let node of nodes) {
			// Retrieve skill category value to organize elements.system (ie. Foundational, Delivering, etc.)
			fetch(node.relationships.field_category.links.related.href)
				.then((res) => res.json())
				.then((categoryData) => {
					if (categoryData.data) {
						const category = categoryData.data.attributes.name;
						const skillLabel = node.attributes.field_label;

						if (skillType === "foundational") {
							foundationalSkills[category] = {
								...foundationalSkills[category],
								[skillLabel]: node
							};

							setElements(elements => ({
								...elements,
								...elements.foundational,
								foundational: sortById(foundationalSkills)
							}));
						}
						if (skillType === "system") {
							systemSkills[category] = {
								...systemSkills[category],
								[skillLabel]: node
							};

							setElements(elements => ({
								...elements,
								...elements.system,
								system: sortById(systemSkills)
							}));
						}
					}
				});
		}
	}

	// Create a Basic Authentication token by encoding the username and password
	const username = "palantir";
	const password = "happy 20th";
	const base64Credentials = btoa(`${username}:${password}`);

	const fetcher = (...args) =>
		fetch(...args, {
			method: "GET",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Methods": "GET",
				"Access-Control-Allow-Origin": "*",
				"Authorization": `Basic ${base64Credentials}`
			}
		})
			.then((res) => res.json())
			.then((cohortData) => {
				setFoundationHeader(foundationalHeader => ({
					...foundationalHeader,
					["description"]: cohortData.data[0].attributes.field_foundational_intro_text.value
				}));

				setSystemHeader(systemHeader => ({
					...systemHeader,
					["description"]: cohortData.data[0].attributes.field_system_intro_text.value
				}));

				// Separates skill paragraph nodes to iterate on by type later
				const foundationalNodes = [];
				const systemNodes = [];

				for (let skill of cohortData.included) {
					if (skill.attributes.parent_field_name === "field_foundational_skills") {
						foundationalNodes.push(skill);
					}
					if (skill.type === "paragraph--system_skills") {
						systemNodes.push(skill);
					}
				}

				if (foundationalNodes.length) {
					setElementsStructure(foundationalNodes, "foundational");
				}
				if (systemNodes.length) {
					setElementsStructure(systemNodes, "system");
				}
			});

	const {isValidating, error } = useSWR(endpoint ? endpoint : null, fetcher, { revalidateOnFocus: false });

	if (!elements.foundational.Foundational || isValidating) {
    return <h2>{`Loading ${details.cohort} skills...`}</h2>;
  }
  if (error) {
    return <h2>{error}</h2>;
  }

	return (
		<main>
			<NavBar details={details} handleChange={handleChange}/>
			<Header details={details} handleChange={handleChange}/>
			<div className="grid scoring">
				<LeftSidebar
					details={details}
					handleChange={handleChange}
					elements={elements}
					foundationalHeader={foundationalHeader}
					systemHeader={systemHeader}
				/>
				<Content details={details} handleChange={handleChange} elements={elements}/>
			</div>
			<MilestoneTable details={details}/>
			<Footer details={details}/>
		</main>
	);
};

PageLayout.propTypes = propTypes();
PageLayout.defaultProps = defaultProps();

export default PageLayout;

import LevelDescriptions from "../../../Components/LevelDescriptions/LevelDescriptions.es6";
import propTypes from "../../../_assets/js/propTypes";
import defaultProps from "../../../_assets/js/defaultProps";

const Content = (props) => {
	const { details, handleChange, elements } = props;

	return (
		<div className="panel">
			<LevelDescriptions details={details} handleChange={handleChange} elements={elements} />
		</div>
	);

};

Content.propTypes = propTypes();
Content.defaultProps = defaultProps();

export default Content;

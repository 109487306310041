const points = {
  foundational: {
    0: 0,
    1: 4,
    2: 12,
    3: 24,
    4: 40,
    5: 60,
  },
  delivering: {
    0: 0,
    1: 2,
    2: 6,
    3: 12,
    4: 20,
    5: 30,
  },
  strengthening: {
    0: 0,
    1: 1,
    2: 3,
    3: 6,
    4: 10,
    5: 15,
  },
  supporting: {
    0: 0,
    1: 1,
    2: 3,
    3: 6,
    4: 10,
    5: 15,
  },
};

export default points;

import propTypes from "../../../_assets/js/propTypes";
import defaultProps from "../../../_assets/js/defaultProps";
import Milestones from "../../../Components/Milestones/Milestones.es6";

const MilestoneTable = (props) => {
  const { details } = props;

  return (
    <div className="career-summary-wrapper">
      <div className="grid">
        <Milestones details={details} />
      </div>
    </div>
  );
};

MilestoneTable.propTypes = propTypes(false);
MilestoneTable.defaultProps = defaultProps(false);

export default MilestoneTable;

import "../../_assets/css/version-bar.css";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";
import {hashToState} from "../../_assets/js/urlHash.es6";
import {useEffect, useState} from "react";
import useSWR from "swr";

const VersionBar = () => {
  const [ version, setVersion ] = useState({});
  const [ recordedVersion, setRecordedVersion ] = useState({});

  useEffect(() => {
    const hash = hashToState();
    setRecordedVersion(hash.recordedVersion);
  },[]);

  let endpoint = 'https://www.palantir.net/jsonapi/block_content/basic';

  // Create a Basic Authentication token by encoding the username and password
  const username = "palantir";
  const password = "happy 20th";
  const base64Credentials = btoa(`${username}:${password}`);

  const fetcher = (...args) =>
    fetch(...args, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Basic ${base64Credentials}`
      }
    })
      .then((res) => res.json())
      .then((basicBlocks) => {
        for (const block of basicBlocks.data) {
          if (block.attributes.info.includes("RBS Version")) {
            setVersion(block);
          }
        }
      });

  const { error } = useSWR(endpoint ? endpoint : null, fetcher, { revalidateOnFocus: false });

  if (error) {
    return <h2>{error}</h2>;
  }

  if (
    !version || !recordedVersion ||
    Object.keys(version).length < 1 ||
    version.attributes.body.value === `Version ${recordedVersion}`
  ) {
    return "";
  }

  return (
    <div className="version-bar">
      Note: You last used {recordedVersion} of the RBS Tool, which is no longer supported.
      <br/>
      <a
        target="_blank"
        href={"https://palantir.atlassian.net/wiki/spaces/PALANTIR/pages/693731364/" +
          "Roles-based+Structure+RBS#Version-History"}>Click here</a> {" "}
      to see the release notes and learn more about what's been updated.
    </div>
  );

};

VersionBar.propTypes = propTypes(false);
VersionBar.defaultProps = defaultProps(false);

export default VersionBar;

import initialState from "../constants/initialState";

const defaultProps = (withHandler = true) => {

  const props = {...initialState};
  if (withHandler) {
    //props.handleChange = () => {};
  }

  return props;
};

export default defaultProps;

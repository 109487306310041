import initialState from "../constants/initialState";

// Turns the ratings into a list.
const ratingsMap = () => {
  let map = [];
  for (const key of Object.keys(initialState.ratings)) {
    map.push(key);
  }
  return map;
};

// Converts state values to a URL hash.
export const stateToHash = (state) => {
  const values = [];

  // Handle ratings.
  for (const value of Object.values(state.ratings)) {
    values.push(value);
  }

  // Handle strings.
  const keys = [
    'level',
    'name',
    'cohort',
    'evaluator',
    'role',
    'version'
  ];
  keys.forEach((key) => {
    values.push(state[key]);
  });

  const hash = values.join(',');
  if (hash) {
    window.location.replace(`#${hash}`);
  }
};

// Converts a URL hash to state values.
export const hashToState = () => {
  const hash = window.location.hash;
  let hashValues;
  if (hash) {
    hashValues = hash.split('#')[1].split(',');
  }
  // No values from URL.
  if (!hashValues) {
    return initialState;
  }

  // Else, load URL values.
  const state = initialState;
  const map = ratingsMap();

  // Calculate the values.
  hashValues.forEach((value, key) => {
    switch (key) {
      case 13:
        state.level = parseInt(hashValues[13]);
        break;
      case 14:
        state.name = decodeURI(hashValues[14]);
        break;
      case 15:
        state.cohort = decodeURI(hashValues[15]);
        break;
      case 16:
        state.evaluator = decodeURI(hashValues[16]);
        break;
      case 17:
        state.role = decodeURI(hashValues[17]);
        break;
      case 18:
        state.recordedVersion = decodeURI(hashValues[18]);
        break;
      default:
        if (key <= 12) {
          state.ratings[map[key]] = parseInt(value);
        }
        break;
    }
  });

  return state;
};

import propTypes from "../../../_assets/js/propTypes";
import defaultProps from "../../../_assets/js/defaultProps";
import GradedLevel from "../../../Components/GradedLevel/GradedLevel.es6";
import SkillsTable from "../../../Components/SkillsTable/SkillsTable.es6";

const LeftSidebar = (props) => {
	const {
		details,
		handleChange,
		elements,
		foundationalHeader,
		systemHeader
	} = props;

	return (
		<div className="panel">
			<GradedLevel details={details}/>
			<SkillsTable
				details={details}
				handleChange={handleChange}
				elements={elements}
				foundationalHeader={foundationalHeader}
				systemHeader={systemHeader}
			/>
		</div>
	);

};

LeftSidebar.propTypes = propTypes();
LeftSidebar.defaultProps = defaultProps();

export default LeftSidebar;

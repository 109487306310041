import propTypes from "../../../_assets/js/propTypes";
import defaultProps from "../../../_assets/js/defaultProps";
import ExportToPDFButton from '../ExportToPDFButton/ExportToPDFButton.es6';
import VersionBar from "../../../Components/VersionBar/VersionBar.es6";
import "../../../_assets/css/nav-bar.css";

const NavBar = (props) => {
  const { details, handleChange } = props;

  return (
    <div id="navigation">
      <div className="version">
        <VersionBar details={details} />
      </div>
      <ExportToPDFButton />
    </div>
  );
};

NavBar.propTypes = propTypes();
NavBar.defaultProps = defaultProps();

export default NavBar;

import { useEffect, useState } from 'react';
import PageLayout from "../Layout/PageLayout/PageLayout.es6";
import ExportToPDFButton from '../Layout/Elements/ExportToPDFButton/ExportToPDFButton.es6';
import calculateScore from "../_assets/js/calculateScore.es6";
import { hashToState, stateToHash } from "../_assets/js/urlHash.es6";
import '../_assets/css/app.css';

const App = () => {
  // Default state objects.
  const [ details, setDetails ] = useState(null);

  // State handler.
  const handleChange = (target) => {
    let newState = {...details};

    // Basic form settings have no action.
    if (target.action === undefined) {
      newState[target.name] = (target.name === 'level') ? parseInt(target.value) : target.value;
    }
    // Switch ratings focus.
    if (target.action === 'focus') {
      newState.activeCategory = target.value;
    }
    // Set a new rating.
    if (target.action === 'rating') {
      newState.ratings[target.name] = parseInt(target.value, 10);
      newState.activeCategory = target.name;
    }
    if (target.action === 'bad_version') {
      newState.recordedVersion = target.recordedVersion;
    }
    // Set the active lookup type.
    if (newState.activeCategory.includes('foundational')) {
      newState.activeType = 'foundational';
    }
    else {
      newState.activeType = 'system';
    }
    // Compute scores.
    const calculatedState = calculateScore({...newState});

    // Set URL.
    stateToHash(calculatedState);

    // Set state.
    setDetails(calculatedState);
  };

  // Set the initial state.
  useEffect(() => {
    const state = hashToState();
    if (state) {
      // Compute scores.
      const calculatedState = calculateScore({...state});
      setDetails(calculatedState);
    }
  },[]);

  // Wait for details to load.
  if (details === null) {
    return "";
  }

  // Return content.
  return (
    <>
      <PageLayout details={details} handleChange={handleChange} />
    </>
  );

};


export default App;

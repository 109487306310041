import "../../_assets/css/milestones.css";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";

const Milestones = (props) => {
  const { details } = props;

  const tierLabels = ["Explorer","Practitioner","Expert","Pollinator","Groundbreaker"];
  const tiers = [1,2,3,4,5];
  const levels = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  let gradedClass = "graded-equal"
  if (details.gradedLevel < details.level) {
    gradedClass = 'graded-low'
  }
  if (details.gradedLevel > details.level) {
    gradedClass = 'graded-high'
  }

  return (
    <div className="career-summary">
      <header className="career-summary__header">
        <h1>Results</h1>
      </header>
      <table className="milestone-table">
        <caption hidden={true}>Milestones Table</caption>
        <thead>
					<tr>
						<th hidden={true}>Milestones Table</th>
					</tr>
				</thead>
        <tbody>
        {/* Tier numbers */}
        <tr>
          {tierLabels.map((tier,i) =>
            <td className="tier-label" colSpan="3" key={i}>{tier}</td>
          )}
        </tr>
        {/* Level numbers up to 15 */}
        <tr>
          {levels.map((level) =>
            <td className="level-label" key={level}>{level}</td>
          )}
        </tr>
        {/* Graded Level */}
        <tr>
          {levels.map((level) => {
            const classname = (level <= details.gradedLevel) ? "graded-full" : "graded-empty";
            return <td className={classname} key={level}></td>
          })}
        </tr>
        {/* Breaker breaker */}
        <tr><td className="breaker" colSpan={15}></td></tr>
        {/* Foundational Tier */}
        <tr>
          {tiers.map((tier) => {
            const classname = (tier <= Math.floor(details.foundationalCap /3)) ? "core-full" : "core-empty";
            return <td className={classname} colSpan="3" key={tier}></td>
          })}
        </tr>
        {/* System Tier */}
        <tr>
          {tiers.map((tier) => {
            const classname = (tier <= Math.floor(details.systemCap /3)) ? "skills-full" : "skills-empty";
            return <td className={classname} colSpan="3" key={tier}></td>
          })}
        </tr>
        </tbody>
      </table>
      <div className="career-summary__key">
        <h2 className="career-summary__key-heading">KEY</h2>
        <div className="key key--graded">
          <span className={gradedClass}>Demonstrated Level: {details.gradedLevel}</span>
        </div>
        <div className="key key--core">
          Foundational Level: {details.foundationalCap}<br />
          <span className="key__meta">
            {details.foundationalScore}
            {details.foundationalCap < 15 ?
              ` of ${details.nextFoundationalPoints + details.foundationalScore} points`
              : ` of 240 points`
            }
          </span>
        </div>
        <div className="key key--skills">
          System Level: {details.systemCap}<br />
          <span className="key__meta">
            {details.systemScore}
            {details.systemCap < 15 ?
              ` of ${details.nextSystemPoints + details.systemScore} points`
              : ` of 180 points`
            }
          </span>
        </div>
      </div>
      {/* START Content below chart. Line breaks are intentional. */}
      <p>&nbsp;</p>
      <h3>Levels &amp; Milestones</h3>
      <p>Your Demonstrated Level (the top row above) is the combined score of the behaviors you demonstrate
        in Foundational skills and System & Consulting skills.
      </p>
      <p>The scores entered in the tool above impact both your Demonstrated Level and your Milestones,
        which correlate with your salary.
      </p>
      <ul>
        <li>
          The first row is your <b>Demonstrated Level</b> of skills/proficiency for your cohort (green bar).
          This ranges from 1-15 and corresponds to your salary level.
        </li>
        <li>
          Rows two &amp; three zoom in on that Demonstrated Level and show where you are in your <b>Foundational
          skills</b> and <b>System &amp; Consulting skills</b>. The corresponding boxes in the Key show the remaining
          points needed to advance to the next level(s).
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <b>Your level falls into {" "}
          <a style={{color: "#fb6500"}} href="https://palantir.atlassian.net/l/cp/U7k1r24s" target="_blank">
            {"one of five milestones"}
          </a> (i.e., Explorer, Practitioner, Expert, Pollinator,
          Groundbreaker). These Milestones act as gates you must pass to move from one milestone to the next.
        </b>
      </p>
      <p>
        <em>Example: For a Demonstrated Level to advance beyond 3, the Foundational level and System level both
          need to be higher than 3. This would then place you in the “Practitioner” milestone and allow you to
          advance your Demonstrated Level. If both scores are at a level 3, you would remain in the “Explorer”
          milestone, and your Demonstrated Level would remain the same.</em>
      </p>
      <p>&nbsp;</p>
      <p>
        For more information about scoring, including examples and salaries, please refer to the {" "}
        <a style={{color: "#fb6500"}} href="https://palantir.atlassian.net/l/cp/zPuhpPX0" target="_blank">
          {"Confluence page"}
        </a>.
      </p>
      {/* END Content below chart */}
    </div>
  );

};

Milestones.propTypes = propTypes(false);
Milestones.defaultProps = defaultProps(false);

export default Milestones;

import propTypes from "../../../_assets/js/propTypes";
import defaultProps from "../../../_assets/js/defaultProps";
import { useEffect, useState } from "react";
const Footer = () => {
  const [ version, setVersion ] = useState({});

  let endpoint = 'https://www.palantir.net/jsonapi/block_content/basic';

  // Create a Basic Authentication token by encoding the username and password
  const username = "palantir";
  const password = "happy 20th";
  const base64Credentials = btoa(`${username}:${password}`);

  useEffect(() => {
    fetch(endpoint, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Basic ${base64Credentials}`
      }
    })
      .then((res) => res.json())
      .then((basicBlocks) => {
        for (const block of basicBlocks.data) {
          if (block.attributes.info.includes("RBS Version")) {
            setVersion(block);
          }
        }
      });
  }, [])

  const getDate = () => {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return new Date().toLocaleString('us-EN', dateOptions)
  };

  return (
    <div className="footer">
      <div className="grid">
        {Object.keys(version).length &&
          <span>
            {version.attributes.body.value}
          </span>
        }
        <span>
          {getDate()}
        </span>
      </div>
    </div>
  );

};

Footer.propTypes = propTypes(false);
Footer.defaultProps = defaultProps(false);

export default Footer;

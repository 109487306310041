import "../../_assets/css/skills-table.css";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";

const SkillsTable = (props) => {
	const { details, handleChange, elements, foundationalHeader, systemHeader } = props;

	// Generate a header row.
	const getHeaders = (cohort) => {
		let output = [];
		for (const [key, value] of Object.entries(cohort)) {
			output.push(
				<td
					className={`track-selector-label skill-${value.attributes.field_keys}`}
					key={value.attributes.field_keys}>{key}
				</td>
			);
		}
		return output;
	};

	// Generate the score.
	const getScore = (cohort, type) => {
		let output = [];
		// eslint-disable-next-line no-unused-vars
		for (const [key, value] of Object.entries(cohort)) {
			output.push(
				<td
					className={`track-selector-value track-background-${type.toLowerCase()}`}
					key={value.attributes.field_keys}
				>
					<button
						className={`score-${value.attributes.field_keys}`}
						onClick={() => setFocus(cohort, type, value.attributes.field_keys)}
					>
						{
							details.ratings[value.attributes.field_keys] === 0
								? "+"
								: details.ratings[value.attributes.field_keys]
						}
					</button>
				</td>
			);
		}
		return output;
	};

	// Generate the add arrows.
	const getAddArrows = (cohort, type) => {
		let output = [];
		// eslint-disable-next-line no-unused-vars
		for (const [key, value] of Object.entries(cohort)) {
			output.push(
				<td
					className={`track-selector-arrow track-light-background-${type.toLowerCase()}`}
					key={value.attributes.field_keys}
				>
					<button className={`add-${value.attributes.field_keys}`}
									onClick={() => addOne(cohort, type, value.attributes.field_keys)}>++
					</button>
				</td>
			);
		}
		return output;
	};

	// Generate the subtract arrows.
	const getSubtractArrows = (cohort, type) => {
		let output = [];
		// eslint-disable-next-line no-unused-vars
		for (const [key, value] of Object.entries(cohort)) {
			output.push(
				<td
					className={`track-selector-arrow track-light-background-${type.toLowerCase()}`}
					key={value.attributes.field_keys}
				>
					<button className={`subtract-${value.attributes.field_keys}`}
									onClick={() => subtractOne(cohort, type, value.attributes.field_keys)}>--
					</button>
				</td>
			);
		}
		return output;
	};

	// Generate the systems elements.
	const getSystemsTables = () => {
		let output = [];
		for (const [key, value] of Object.entries(elements.system)) {
			output.push(
				<table key={key}>
					<thead>
					<tr>
						<th colSpan="3"><h3 className="section__subtitle">{key}</h3></th>
					</tr>
					</thead>
					<tbody>
					<tr>
						{getHeaders(value)}
					</tr>
					<tr>
						{getAddArrows(value, key.toLowerCase())}
					</tr>
					<tr>
						{getScore(value, key.toLowerCase())}
					</tr>
					<tr>
						{getSubtractArrows(value, key.toLowerCase())}
					</tr>
					</tbody>
				</table>
			);
		}

		return output;
	};

	// Increment by one.
	const addOne = (cohort, type, skill) => {
		const rating = details.ratings[skill];
		if (rating < 5) {
			const target = {
				action: "rating",
				name: skill,
				value: rating + 1,
				activeType: type,
				cohort: cohort
			};
			handleChange(target);
		} else {
			setFocus(cohort, type, skill);
		}
	};

	// Decrement by one.
	const subtractOne = (cohort, type, skill) => {
		const rating = details.ratings[skill];
		if (rating > 0) {
			const target = {
				action: "rating",
				name: skill,
				value: rating - 1,
				activeType: type,
				cohort: cohort
			};
			handleChange(target);
		} else {
			setFocus(cohort, type, skill);
		}
	};

	// Switch to a new cohort.
	const setFocus = (cohort, type, skill) => {
		const target = {
			action: "focus",
			value: skill,
			activeType: type,
			cohort: cohort
		};
		handleChange(target);
	};

	if (elements.foundational.Foundational === undefined || elements.system === undefined) {
		return "";
	}

	// HTML output.
	return (
		<div className="bounding-table">
			{
				foundationalHeader.description && (
					<header className="section">
						<h2 className="section__title">{foundationalHeader.label}</h2>
						<p>{foundationalHeader.description}</p>
					</header>
				)
			}
			<table>
				<thead>
					<tr>
						<th hidden={true}>Foundational Skills</th>
					</tr>
				</thead>
				<tbody>
				<tr>
					{getHeaders(elements.foundational.Foundational)}
				</tr>
				<tr>
					{getAddArrows(elements.foundational.Foundational, "foundational")}
				</tr>
				<tr>
					{getScore(elements.foundational.Foundational, "foundational")}
				</tr>
				<tr>
					{getSubtractArrows(elements.foundational.Foundational, "foundational")}
				</tr>
				</tbody>
			</table>
			<header className="section">
				<h2 className="section__title">{systemHeader.label}</h2>
				<p>{systemHeader.description}</p>
			</header>
			{getSystemsTables()}
		</div>
	);
};

SkillsTable.propTypes = propTypes();
SkillsTable.defaultProps = defaultProps();

export default SkillsTable;
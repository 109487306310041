import "../../_assets/css/graded-level.css";
import propTypes from "../../_assets/js/propTypes";
import defaultProps from "../../_assets/js/defaultProps";

const GradedLevel = (props) => {
  const { details } = props;

  // Grid math.
  const totalCols = 10;
  const totalPoints = details.nextLevelPoints + details.score;
  const pointsPerCol = Math.ceil((totalPoints - details.thisLevelPoints) / 10);
  const filledCols = Math.floor( (details.score - details.thisLevelPoints) / pointsPerCol);

  return (
    <div className="chart">
      <span className="summary">
        Demonstrated level: {details.gradedLevel}
      </span>
      <span className="summary__meta">
        {details.nextLevelPoints} overall points to next level
      </span>
      <table className="graded-level">
        <thead>
					<tr>
						<th hidden={true}>Demonstrated Points</th>
					</tr>
				</thead>
        <tbody>
        <tr className="graded">
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
          <td className="graded-levels"> </td>
        </tr>
        <tr className="graded-levels">
          { filledCols > 0 &&
          <td className="graded-total-full" colSpan={filledCols}> </td>
          }
          <td className="graded-total-empty" colSpan={totalCols - filledCols}> </td>
        </tr>
        <tr>
          <td className="graded-current-level">{details.gradedLevel}</td>
          <td className="graded-levels" colSpan={totalCols - 2}> </td>
          <td className="graded-next-level">{details.gradedLevel + 1}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );

};

GradedLevel.propTypes = propTypes(false);
GradedLevel.defaultProps = defaultProps(false);

export default GradedLevel;
